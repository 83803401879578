<template>
  <div class="md:px-6 py-10 px-10 mb-5 md:pl-5">
    <div v-if="!$lodash.isEmpty(notification)" class="antialiased space-y-2">
      <p class="text-xs">
        {{ $moment(notification?.created_at).format("DD MMM YYYY hh:mm a") }}
      </p>
      <p class="font-medium">
        {{ notification.data?.title }}
      </p>
      <p class="text-sm">
        {{ notification.data?.message }}
      </p>
    </div>
    <div v-else>{{ $t('general.no_data_found') }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    notification() {
      return this.$store.getters["userStore/notification"];
    },
  },

  mounted() {
    this.showNotification();
  },

  methods: {
    async showNotification() {
      if (this.$route.params.notification_id) {

        await this.$store.dispatch(
          "userStore/showNotification",
          this.$route.params.notification_id
        );

        await this.$store.dispatch(
          "userStore/markAsReadNotification",
          this.$route.params.notification_id
        );

        await this.$store.dispatch("userStore/notificationUnread");
      }
    },
  },

  watch: {
    $route() {
      this.showNotification();
    },
  },
};
</script>
